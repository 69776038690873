<template>
  <div class="relative flex h-screen bg-teal-100">
    <!-- Updated background container -->
    <div class="background-container">
      <div class="background-layer"></div>
      <div class="shapes-layer"></div>
      <div class="circles-layer"></div>
      <div class="patterns-layer"></div>
      <div class="radial-layer"></div>
      <div class="image-layer"></div>
      <div class="animated-layer"></div>
      <div class="gradient-overlay"></div>
    </div>

    <!-- Full width container -->
    <div class="w-full flex flex-col md:flex-row">
      <!-- Left panel (45% width) - Hidden on mobile and tablet -->
      <div class="hidden md:flex w-[45%] absolute left-0 top-0 bottom-0 p-8 ml-24 items-center justify-center">
        <div class="w-full max-w-lg mx-auto">
          <div v-if="organizationProfileImage" class="mb-2 flex justify-start items-center">
            <div class="w-16 h-16 rounded-full overflow-hidden border-4 border-gray-200 shadow-lg">
              <img :src="organizationProfileImage" alt="Profile" class="w-full h-full object-cover" />
            </div>
          </div>

          <div v-if="!organizationProfileImage" class="mb-2 flex justify-start items-center">
            <!-- <font-awesome-icon icon="fa-regular fa-id-card" class="w-12 h-12 text-gray-700" /> -->
          </div>
          <h2 class="text-3xl font-semibold text-gray-700 leading-tight max-w-md mb-1">
            {{ headerText }}  
          </h2>          
          <p class="text-lg text-gray-600 mb-8 max-w-xs">
            {{ subTitleText }}
          </p>
          <ul class="space-y-8 relative">
            <div class="absolute left-3 top-0 bottom-6 w-0.5 bg-gray-200 rounded-full"></div>
            <template v-for="(step, index) in steps" :key="index">
              <li class="flex items-start group cursor-pointer transition-all duration-300 ease-in-out"
                @click="navigateToStep(index)" :class="{
                    'opacity-70 -ml-1': !step.active && currentStep !== index,
                    'scale-[1.006]': currentStep === index
                  }">
                <!-- Step indicator -->
                <div :class="[
                  'w-[36px] h-[36px] rounded-full flex items-center justify-center mr-4 z-10 transition-all duration-300 ease-in-out',
                  step.validated ? 'bg-teal-400 text-white' : 'bg-white border border-gray-300 text-gray-500',
                  step.validated ? 'group-hover:bg-gray-600' : 'group-hover:bg-gray-400'
                ]">
                  <span v-if="step.validated" class="text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                  <span v-else class="text-sm font-medium flex items-center justify-center">
                    {{ index + 1 }}
                  </span>                
                </div>
                <!-- Step details -->
                <div class="flex-1">
                  <div class="flex items-center mb-1">
                    <font-awesome-icon :icon="step.icon" class="w-4 h-4 mr-2" :class="[
                      step.validated ? 'text-gray-800' : 'text-gray-600',
                      'group-hover:text-gray-600'
                    ]" />
                    <strong :class="[
                      'block text-lg font-medium transition-colors duration-300',
                      step.validated ? 'text-gray-800' : 'text-gray-600',
                      'group-hover:text-gray-600'
                    ]">
                      {{ step.title }}
                    </strong>
                  </div>
                  <div class="flex items-start">
                    <font-awesome-icon icon="info-circle" class="w-4 h-4 mr-2 mt-1" :class="[
                      step.validated ? 'text-gray-700' : 'text-gray-600'
                    ]" />
                    <p :class="[
                      'text-sm leading-relaxed',
                      step.validated ? 'text-gray-700' : 'text-gray-600'
                    ]">
                      {{ step.description }}
                    </p>
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <!-- Right panel (full width on mobile, 55% on larger screens) -->
      <div class="w-full md:w-[55%] flex flex-col justify-center items-start relative z-10"
        :class="{ 'md:ml-[40%]': currentStep < steps.length - 1, 'md:ml-[45%]': currentStep == steps.length - 1 }">
        <div class="w-full">
          <transition name="slide-fade" mode="out-in">
            <div v-if="isLoading" class="flex justify-center items-center h-64">
              <font-awesome-icon icon="fa-duotone fa-spinner-third" class="w-8 h-8" spin />
            </div>
            <component v-else :is="currentStepComponent" :key="currentStep" :userData="userData"
              :profileImage="profileImage" :organizationInfo="organizationInfo" @form-validated="handleFormValidated"
              @check-form-validated="handleCheckValidation" @go-back="goBack" @image-uploaded="handleImageUpload"
              @register-complete="handleRegisterSuccess" @accepted-terms-changed="handleAcceptTerms"
              @cheer-validated="handleCheerValidation" @add-cheer-data="handleCheerUpdate" />
          </transition>
        </div>
      </div>
    </div>

    <button @click="handleBackToLogin"
      class="fixed bottom-8 left-8 text-gray-700 font-medium py-2 px-4 transition duration-300 ease-in-out z-50 hidden md:block">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block mr-2" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd"
          d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
          clip-rule="evenodd" />
      </svg>
      Back to Login
    </button>
  </div>
</template>

<script>
import { ref, computed, defineAsyncComponent, markRaw, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'

export default {
  name: 'DynamicStepRegistration',
  props: {
    initialSteps: {
      type: Array,
      default: () => []
    },
    orgID: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const currentStep = ref(0)
    const isLoading = ref(false)
    const acceptedTerms = ref(false)
    const steps = ref(props.initialSteps)
    const userData = ref({})
    const profileImage = ref('')
    const organizationInfo = ref(null)
    const headerText = ref('Create your account!')
    const subTitleText = ref('Join RefReps and help the world see the game through the eyes of the officials!')
    const organizationProfileImage = ref('')
    const orgID = ref('')

    // New ref to track the validation state of each step
    const stepValidationState = ref({})

    /* @vite-ignore */
    const currentStepComponent = computed(() => {
      const step = steps.value[currentStep.value]
      if (typeof step.component === 'string') {
        return defineAsyncComponent(() => import(`@/components/Login/Register/${step.component}.vue`))
      } else if (typeof step.component === 'object' && step.component !== null) {
        return markRaw(step.component)
      } else {
        console.error(`Invalid component for step: ${step.title}`)
        return null
      }
    })

    const isCurrentStepValid = computed(() => {
      return stepValidationState.value[currentStep.value] || false
    })

    const isReadyStepComplete = computed(() => {
      return acceptedTerms.value && isCurrentStepValid.value
    })

    const fetchOrganizationInfo = async () => {
      const organizationId = route.query.id || props.orgID
      if (organizationId) {
        try {
          isLoading.value = true
          const response = await axios.get(`${import.meta.env.VITE_BASE_LINK}/get-organization-info/${organizationId}`)
          organizationInfo.value = response.data
          headerText.value = `Setup your account to join ${organizationInfo.value.name}`
          subTitleText.value = `${organizationInfo.value.instructors[0].name} has invited you to join their organization.`
          organizationProfileImage.value = organizationInfo.value.profileImage

          // Add companyID to userData
          userData.value.companyID = organizationId

          // Remove the 'organization' parameter from the URL
          const newQuery = { ...route.query }
          delete newQuery.organization
          router.replace({ query: newQuery })
        } catch (error) {
          console.error('Failed to fetch organization info:', error)
          // Handle error (e.g., show a notification to the user)
        } finally {
          isLoading.value = false
        }
      }
    }

     // Function to reset all values to default
     const resetToDefaults = () => {
      currentStep.value = 0
      isLoading.value = false
      acceptedTerms.value = false
      steps.value = props.initialSteps
      userData.value = {}
      profileImage.value = ''
      organizationInfo.value = null
      headerText.value = 'Create your account!'
      subTitleText.value = 'Join RefReps and help the world see the game through the eyes of the officials!'
      organizationProfileImage.value = ''
      stepValidationState.value = {}
      
      // Reset validation state for all steps
      steps.value.forEach((step, index) => {
        stepValidationState.value[index] = false
        step.validated = false
        step.active = index === 0 // Only the first step should be active
      })
      localStorage.removeItem('personalDetailsFormData');
      localStorage.removeItem('profileImage', '');
    }

    onMounted(() => {
      resetToDefaults()
      fetchOrganizationInfo()
    })

    const handleCheerValidation = (formData) => {
      userData.value = { ...userData.value, ...formData }
      stepValidationState.value[currentStep.value] = true
      steps.value[currentStep.value].validated = true
      if (currentStep.value < steps.value.length - 1) {
        currentStep.value++
        updateStepsActive()
      }
      emit('form-validated', formData)
    }

    // when cheer updates the section and position, it will update the userData
    const handleCheerUpdate = (data) => {
      const { position, section } = data;
      userData.value = {
        ...userData.value,
        section,
        position,
        metadata: {
          ny: {
            position,
            section
          }
        }
      }
      emit('add-cheer-data', { position, section });
    }

    const handleBackToLogin = () => {
      emit('back-to-home')
      resetForm()
    }

    const goBack = () => {
      if (currentStep.value > 0) {
        currentStep.value--
        updateStepsActive()
      }
    }

    const handleRegisterSuccess = (userData) => {
      resetForm()
      emit('register-complete', userData)
    }

    const handleFormValidated = (formData) => {
      userData.value = { ...userData.value, ...formData }
      stepValidationState.value[currentStep.value] = true
      steps.value[currentStep.value].validated = true
      if (currentStep.value < steps.value.length - 1) {
        currentStep.value++
        updateStepsActive()
      }
    }

    const handleImageUpload = (imageUrl) => {
      profileImage.value = imageUrl
      userData.value = { ...userData.value, profileImage: imageUrl }
      stepValidationState.value[currentStep.value] = true
      steps.value[currentStep.value].validated = true
      if (currentStep.value < steps.value.length - 1) {
        currentStep.value++
        updateStepsActive()
      }
    }

    const resetValidationState = () => {
      stepValidationState.value = {}
      steps.value.forEach((step, index) => {
        stepValidationState.value[index] = false
        step.validated = false
      })
    }

    const resetForm = () => {
      currentStep.value = 0
      userData.value = {}
      profileImage.value = ''
      acceptedTerms.value = false
      resetValidationState()
      steps.value.forEach((step) => {
        step.active = false
      })
      steps.value[0].active = true
    }

    const handleCheckValidation = (isValid) => {
      stepValidationState.value[currentStep.value] = isValid
      steps.value[currentStep.value].validated = isValid
    }

    const navigateToStep = (index) => {
      if (
        index <= currentStep.value ||
        steps.value[index].validated ||
        (index === currentStep.value + 1 && stepValidationState.value[currentStep.value])
      ) {
        currentStep.value = index
        updateStepsActive()
      }
    }

    const updateStepsActive = () => {
      steps.value.forEach((step, index) => {
        step.active = index <= currentStep.value
      })
    }

    const handleAcceptTerms = (accepted) => {
      acceptedTerms.value = accepted
      stepValidationState.value[currentStep.value] = accepted
      steps.value[currentStep.value].validated = accepted
    }

    const addStep = (newStep) => {
      steps.value.push(newStep)
      stepValidationState.value[steps.value.length - 1] = false
    }

    const removeStep = (index) => {
      if (index >= 0 && index < steps.value.length) {
        steps.value.splice(index, 1)
        const newValidationState = {}
        Object.keys(stepValidationState.value).forEach(key => {
          if (Number(key) < index) {
            newValidationState[key] = stepValidationState.value[key]
          } else if (Number(key) > index) {
            newValidationState[Number(key) - 1] = stepValidationState.value[key]
          }
        })
        stepValidationState.value = newValidationState
        if (currentStep.value >= steps.value.length) {
          currentStep.value = steps.value.length - 1
        }
        updateStepsActive()
      }
    }
    

    return {
      isLoading,
      steps,
      currentStep,
      currentStepComponent,
      handleImageUpload,
      userData,
      profileImage,
      goBack,
      resetForm,
      handleRegisterSuccess,
      handleFormValidated,
      navigateToStep,
      handleCheckValidation,
      handleAcceptTerms,
      acceptedTerms,
      handleBackToLogin,
      isCurrentStepValid,
      isReadyStepComplete,
      addStep,
      removeStep,
      handleCheerValidation,
      organizationInfo,
      organizationProfileImage,
      headerText,
      subTitleText,
      resetToDefaults,
      handleCheerUpdate
    }
  }
}
</script>

<style scoped>

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

:root {
  --teal-50: rgb(240, 253, 250);
  --teal-100: rgb(204, 251, 241);
  --teal-200: rgb(153, 246, 228);
  --teal-300: rgb(94, 234, 212);
  --teal-400: rgb(45, 212, 191);
}

.background-container {
  position: absolute;
  inset: 0;
  background-color: #e6f7f5;  /* Light teal background */
  opacity: 1;
  background-image: 
    radial-gradient(#b2ebf2 0.5px, transparent 0.5px), 
    radial-gradient(#b2ebf2 0.5px, #e6f7f5 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

.background-layer {
  position: absolute;
  inset: 0;
  background: 
    /* Base gradient */
    linear-gradient(to bottom, var(--teal-50), white);
}

.shapes-layer {
  position: absolute;
  inset: 0;
  opacity: 0.2;
  background: 
    /* Shapes */
    radial-gradient(circle at bottom left, var(--teal-400) 25%, transparent 25.5%) -32% 125% / 64px 64px no-repeat,
    radial-gradient(circle at bottom left, var(--teal-300) 25%, transparent 25.5%) -33.33% 133.33% / 96px 96px no-repeat;
}

.circles-layer {
  position: absolute;
  inset: 0;
  opacity: 0.1;
  background: 
    /* Circles */
    radial-gradient(circle, var(--teal-200) 50%, transparent 50%) 30% 20% / 100px 100px no-repeat,
    radial-gradient(circle, var(--teal-200) 50%, transparent 50%) 70% 60% / 150px 150px no-repeat,
    radial-gradient(circle, var(--teal-200) 50%, transparent 50%) 50% 40% / 80px 80px no-repeat,
    radial-gradient(circle, var(--teal-200) 50%, transparent 50%) 10% 80% / 120px 120px no-repeat,
    radial-gradient(circle, var(--teal-200) 50%, transparent 50%) 90% 10% / 90px 90px no-repeat;
}

.patterns-layer {
  position: absolute;
  inset: 0;
  opacity: 0.05;
  background: 
    /* Patterns */
    linear-gradient(135deg, rgba(66, 153, 225, 0.1) 25%, transparent 25%) 10px 0 / 40px 40px,
    linear-gradient(225deg, rgba(66, 153, 225, 0.1) 25%, transparent 25%) 10px 0 / 40px 40px,
    linear-gradient(45deg, rgba(66, 153, 225, 0.1) 25%, transparent 25%) 0 0 / 40px 40px,
    linear-gradient(315deg, rgba(66, 153, 225, 0.1) 25%, transparent 25%) 0 0 / 40px 40px;
}

.radial-layer {
  position: absolute;
  inset: 0;
  opacity: 0.03;
  background: 
    /* Radial gradients */
    radial-gradient(circle at 50% 50%, rgba(66, 153, 225, 0.2) 0%, transparent 60%),
    radial-gradient(circle at 0% 0%, rgba(239, 68, 68, 0.2) 0%, transparent 60%),
    radial-gradient(circle at 100% 100%, rgba(16, 185, 129, 0.2) 0%, transparent 60%);
}

.image-layer {
  position: absolute;
  inset: 0;
  opacity: 0.37;
  background: url('https://d3sjyulja6yeep.cloudfront.net/files/sports.jpg') 0 0 / 200px repeat;
  mix-blend-mode: soft-light;
}

.animated-layer {
  position: absolute;
  inset: 0;
  opacity: 0.02;
  background: repeating-linear-gradient(
    45deg,
    rgba(66, 153, 225, 0.1) 0,
    rgba(66, 153, 225, 0.1) 1px,
    transparent 1px,
    transparent 20px
  );
  animation: pulse 8s infinite;
}

.gradient-overlay {
  position: absolute;
  inset: 0;
  opacity: 0.1;
  background: linear-gradient(to top right, transparent, var(--teal-100), transparent);
}

@keyframes pulse {
  0%, 100% { opacity: 0.02; }
  50% { opacity: 0.04; }
}

</style>