<template>
<div class="max-w-md mx-auto relative " :class="{ 'gradient-border': developerMode }">
    <transition name="slide-fade" mode="out-in">
        <div v-if="!showForgot" class="transition translate duration-500 ease-in-out h-[350px]">
          <img v-if="formType == 'simple'" src="https://api.refreps.com/images/refreps_logo_web.png" class="w-12 h-12 mb-6" alt="" />
            <transition name="slide-fade-fast" mode="out-in">
              
                <div v-if="!developerMode" key="normal" class="h-24 mb-4">
                  
                    <h2 class="text-4xl font-semibold transition-all duration-300" :class="{ 'blur-sm': isLoading, 'mb-0': !errorMessage }">Welcome back</h2>
                    <h2 class="text-4xl font-semibold transition-all duration-300" :class="{ 'blur-sm': isLoading, 'mb-6': !errorMessage }">to RefReps!</h2>
                </div>
                <div v-else key="dev" class="text-center h-24">
                    <font-awesome-icon icon="fa-solid fa-code" class="w-[35px] h-[35px]" />
                    <h2 class="text-2xl font-semibold transition-all duration-300 animate-pulse" :class="{ 'blur-sm': isLoading, 'mb-6': !localErrorMessage }">Developer Mode</h2>
                </div>
            </transition>

            <transition name="slide-fade" mode="out-in">
                <form @submit.prevent="handleSubmit" :class="{ 'blur-sm': isLoading }" class="transition translate duration-500 ease-in-out">
                    <div class="p-2.5 border border-gray-200 relative transition-all duration-300 ease-in-out rounded-t-lg bg-gradient-to-br from-gray-50 to-white" :class="{ 'bg-gray-50/50': activeField === 'email', 'rounded-lg': developerMode }">
                        <div class="flex items-center">
                            <transition name="slide-fade-fast" mode="out-in">
                                <font-awesome-icon v-if="isValidEmail && !emailWarning" icon="circle-check" class="text-teal-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                                <font-awesome-icon v-else-if="emailWarning" icon="fa-circle-exclamation" class="text-yellow-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                            </transition>
                            <label for="email" class="block text-xs font-medium text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'email' }">Email address</label>
                        </div>
                        <input type="email" id="email" v-model="email" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="johndoe@example.com" @focus="handleFocus('email')" @blur="handleBlur('email')" @input="handleInput" autocomplete="off">
                    </div>
                    <transition name="slide-fade-fast">
                        <div v-if="!developerMode" class="p-2.5 border border-gray-200 relative transition-all duration-300 ease-in-out rounded-b-lg border-t-0 bg-gradient-to-br from-gray-50 to-white" :class="{ 'bg-gray-50/50': activeField === 'password' }">
                            <div class="flex items-center">
                                <transition name="slide-fade-fast" mode="out-in">
                                    <font-awesome-icon v-if="isValidPassword && !passwordWarning" icon="circle-check" class="text-teal-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                                    <font-awesome-icon v-else-if="passwordWarning" icon="fa-circle-exclamation" class="text-yellow-500 mr-1 absolute left-2.5 top-1/2 -mt-1" />
                                </transition>
                                <label for="password" class="block text-xs font-medium text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'password' }">Password</label>
                            </div>
                            <div class="relative">
                                <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pr-10 pl-6" placeholder="••••••••" @focus="handleFocus('password')" @blur="handleBlur('password')" @input="handleInput" autocomplete="new-password">
                                <button type="button" @click="showPassword = !showPassword" class="absolute inset-y-0 right-0 pr-3 flex items-center transition-all duration-300">
                                    <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" class="text-gray-400" />
                                </button>
                            </div>
                        </div>
                    </transition>
                    <transition name="slide-fade-fast" appear>
                        <p v-if="errorMessage" class="mt-4 text-red-500 text-sm text-center slab mb-6 flex items-center justify-center transition-all duration-300">
                            <font-awesome-icon icon="fa-solid fa-exclamation-circle" class="mr-2" />
                            {{ errorMessage }}
                        </p>
                    </transition>
                    <transition name="slide-fade-fast">
                        <div v-if="!developerMode && formType == 'full'" class="flex items-center justify-between mt-2 transition-all duration-300">
                            <div class="flex items-center">
                                <input type="checkbox" id="remember" v-model="rememberMe" class="h-4 w-4 text-teal-400 border-gray-300 rounded transition-all duration-300 ease-in-out transform hover:scale-110 checked:bg-teal-400 checked:border-transparent focus:ring-0 focus:ring-offset-0">
                                <label for="remember" class="ml-2 text-sm text-gray-600 transition-all duration-300">Remember me</label>
                            </div>
                            <a href="#" class="text-sm text-teal-600 hover:underline transition-all duration-300" @click.prevent="emitShowForgot">Forgot password</a>
                        </div>
                    </transition>
                    <div class="space-y-4 mt-6 transition-all duration-300">
                        <button type="submit" class="w-full bg-teal-500 hover:bg-teal-600 text-white font-medium py-3 px-6 rounded-md transition duration-300 ease-in-out flex items-center justify-center space-x-2 group" :disabled="isLoading">
                            <transition name="slide-fade" mode="out-in">
                                <font-awesome-icon icon="fa-solid fa-right-to-bracket" class="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out -translate-x-2 group-hover:translate-x-0" />
                            </transition>
                            <span>Login</span>
                        </button>
                        <div v-if="formType == 'full'" class="text-center transition-all duration-300">
                            <span class="text-sm text-gray-600">Don't have an account?</span>
                            <button type="button" @click="emitShowRegister" class="ml-2 text-sm text-teal-600 hover:underline font-medium transition-all duration-300">Sign up</button>
                        </div>
                    </div>
                </form>
            </transition>

            <p class="mt-6 text-xs text-gray-500 text-center transition-all duration-300">
                By signing up, you agree to RefRep's <a href="https://www.refreps.com/terms" target="_blank" class="underline text-teal-400 transition-all duration-300">Terms and Conditions</a> & <a href="https://www.refreps.com/privacy" target="_blank" class="underline text-teal-400 transition-all duration-300">Privacy Policy</a>
            </p>
        </div>
    </transition>

    <div v-if="isLoading" class="fixed inset-0 bg-white bg-opacity-70 flex items-center justify-center z-50 transition-all duration-300">
        <font-awesome-icon icon="fa-duotone fa-spinner-third" class="text-4xl text-teal-500" spin />
    </div>

</div>
</template>

<script>
import { ref, computed, watch, toRef, onMounted, onUnmounted } from 'vue';
import useMainStore from "@/stores/main";
import { useRouter } from 'vue-router';

export default {
  props: {
    resetUserData: {
      type: Object,
      default: () => ({})
    },
    didResetPassword: {
      type: Boolean,
      default: false
    },
    handleLogin: {
      type: Function,
      required: true,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    emailWarning: {
      type: Boolean,
      default: false
    },
    passwordWarning: {
      type: Boolean,
      default: false
    },
    showOverlay: {
      type: Boolean,
      default: true
    },
    formType: {
      type: String,
      default: 'full'
    },
    course: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const mainStore = useMainStore();

    // Create refs from props
    const propIsLoading = toRef(props, 'isLoading');
    const propErrorMessage = toRef(props, 'errorMessage');
    const propEmailWarning = toRef(props, 'emailWarning');
    const propPasswordWarning = toRef(props, 'passwordWarning');
    const propResetUserData = toRef(props, 'resetUserData');
    const propDidResetPassword = toRef(props, 'didResetPassword');

    const email = ref('');
    const password = ref('');
    const rememberMe = ref(false);
    const emailError = ref('');
    const passwordError = ref('');
    const showPassword = ref(false);
    const showForgot = ref(false);
    const isTyping = ref(false);
    const typingTimeout = ref(null);
    const isFocused = ref({
      email: false,
      password: false
    });
    const developerMode = ref(false);
    const keySequence = ref([]);
    const lastKeyPressTime = ref(0);
    const lastShakeTime = ref(0);
    const shakeCount = ref(0);
    const activeField = ref(null);
    const attemptingSignIn = ref(false);
    const localIsLoading = ref(propIsLoading.value);
    const localErrorMessage = ref(propErrorMessage.value);
    const localEmailWarning = ref(propEmailWarning.value);
    const localPasswordWarning = ref(propPasswordWarning.value);

    const isValidEmail = computed(() => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value));
    const isValidPassword = computed(() => password.value.length > 0);

    watch(propIsLoading, (newValue) => {
      localIsLoading.value = newValue;
    });

    watch(propErrorMessage, (newValue) => {
      localErrorMessage.value = newValue;
    });

    watch(propEmailWarning, (newValue) => {
      localEmailWarning.value = newValue;
    });

    watch(propPasswordWarning, (newValue) => {
      localPasswordWarning.value = newValue;
    });

    watch(propResetUserData, (newValue) => {
      if (newValue && newValue.email) {
        email.value = newValue.email;
      }
    });

    watch(propDidResetPassword, (newValue) => {
      if (newValue) {
        // Handle post-password reset logic if needed
      }
    });

    const handleSubmit = async () => {

        if (developerMode.value) {
                await props.handleLogin({
            email: email.value,
            password: password.value,
            rememberMe: rememberMe.value,
            developerMode: true
            });
            return
        };

      resetErrors();
      if (validateForm()) {
        await props.handleLogin({
          email: email.value,
          password: password.value,
          rememberMe: rememberMe.value,
          developerMode: false
        });
      }
    };

    const emitShowRegister = () => emit('switch-to-register');
    const emitShowForgot = () => emit('switch-to-forgot-start');

    const validateForm = () => {
      let isValid = true;
      if (!email.value) {
        emailError.value = "Email is required";
        isValid = false;
      } else if (!isValidEmail.value) {
        emailError.value = "Email is invalid";
        isValid = false;
      }
      if (!password.value) {
        passwordError.value = "Password is required";
        isValid = false;
      }
      return isValid;
    };

    const resetErrors = () => {
      emailError.value = '';
      passwordError.value = '';
      localErrorMessage.value = '';
      localEmailWarning.value = false;
      localPasswordWarning.value = false;
    };

    const handleInput = () => {
      isTyping.value = true;
      localEmailWarning.value = false;
      localPasswordWarning.value = false;
      clearTimeout(typingTimeout.value);
      typingTimeout.value = setTimeout(() => {
        isTyping.value = false;
      }, 500);
    };

    const handleFocus = (field) => {
      activeField.value = field;
      isFocused.value[field] = true;
      if (field === 'email') {
        localEmailWarning.value = false;
      } else if (field === 'password') {
        localPasswordWarning.value = false;
      }
    };

    const handleBlur = (field) => {
      activeField.value = null;
      isFocused.value[field] = false;
    };

    const handleKeyDown = (event) => {
      const currentTime = Date.now();
      if (currentTime - lastKeyPressTime.value > 2000) {
        keySequence.value = [];
      }
      lastKeyPressTime.value = currentTime;

      if (event.key === 'Escape') {
        keySequence.value = [];
        developerMode.value = false;
        return;
      }

      if (event.shiftKey) {
        const key = event.key.toLowerCase();
        if ('tojupiter'.includes(key)) {
          keySequence.value.push(key);
          if (keySequence.value.join('') === 'tojupiter') {
            developerMode.value = true;
            keySequence.value = [];
          }
        } else {
          keySequence.value = [];
        }
      } else {
        keySequence.value = [];
      }
    };

    const handleKeyUp = (event) => {
      // Any additional logic for key up event
    };

    const handleShake = (event) => {
      const acceleration = event.accelerationIncludingGravity;
      const currentTime = new Date().getTime();
      const shakeThreshold = 15;

      if (acceleration && currentTime - lastShakeTime.value > 500) {
        const deltaX = Math.abs(acceleration.x);
        const deltaY = Math.abs(acceleration.y);
        const deltaZ = Math.abs(acceleration.z);

        if (deltaX > shakeThreshold || deltaY > shakeThreshold || deltaZ > shakeThreshold) {
          shakeCount.value++;
          lastShakeTime.value = currentTime;

          if (shakeCount.value === 3) {
            developerMode.value = true;
            shakeCount.value = 0;
          }
        }
      }

      if (currentTime - lastShakeTime.value > 2000) {
        shakeCount.value = 0;
      }
    };

    onMounted(() => {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    });

    onUnmounted(() => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    });

    return {
      email,
      password,
      rememberMe,
      emailError,
      passwordError,
      showPassword,
      showForgot,
      isTyping,
      isFocused,
      developerMode,
      activeField,
      attemptingSignIn,
      localIsLoading,
      localErrorMessage,
      localEmailWarning,
      localPasswordWarning,
      isValidEmail,
      isValidPassword,
      handleSubmit,
      emitShowRegister,
      emitShowForgot,
      handleInput,
      handleFocus,
      handleBlur,
      handleKeyDown,
      handleKeyUp,
      handleShake,
      resetErrors
    };
  }
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.4s ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateX(15px);
}

.gradient-border {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    background: white;
    z-index: 1;
    animation: 1s ease-in-out;
}

.gradient-border::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    z-index: -1;
    background-size: 400%;
    filter: blur(10px);
    opacity: 0;
    border-radius: 10px;
    animation:
        springIn 1.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        slowFadeIn 2s ease-out 0.8s forwards,
        glowing 20s linear infinite 0.8s;
}

.gradient-border::after {
    content: '';
    position: absolute;
    inset: 0;
    background: white;
    z-index: -1;
    border-radius: 8px;
}

@keyframes springIn {
    0% {
        transform: scale(0.5);
        opacity: 0.7;
    }

    40% {
        transform: scale(1.02);
        opacity: 0.7;
    }

    60% {
        transform: scale(0.98);
        opacity: 0.7;
    }

    80% {
        transform: scale(1.01);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 0.7;
    }
}

@keyframes slowFadeIn {
    0% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@keyframes slideFadeIn {
    0% {
        transform: translateY(-5px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFadeOut {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-5px);
        opacity: 0;
    }
}

.slide-fade-fast-enter-active {
    animation: slideFadeIn 0.3s ease-out;
}

.slide-fade-fast-leave-active {
    animation: slideFadeOut 0.3s ease-in;
}
</style>
