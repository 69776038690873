<template>
<div class="relative min-h-[100vh] flex items-center justify-center overflow-hidden">
    <div class="w-full max-w-xl space-y-8 p-8 relative z-10">
        <form @submit.prevent="saveUserData" class="space-y-4" autocomplete="off">
            <div class="bg-white rounded-xl shadow-2xl overflow-hidden relative z-20" style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 5px 15px -3px rgba(0, 0, 0, 0.1), 0 0 20px 0px rgba(0, 200, 200, 0.1);">
                <div class="grid grid-cols-2">
                    <div class="p-3 border-b border-r border-gray-200 relative transition-all duration-300 ease-in-out" :class="{ 'bg-gray-50/50': activeField === 'firstName' }">
                        <transition name="fade">
                            <font-awesome-icon v-if="firstName" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400" />
                        </transition>
                        <label for="firstName" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'firstName' }">First Name</label>
                        <input type="text" id="firstName" v-model="firstName" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="John" @focus="activeField = 'firstName'" @blur="activeField = null" autocomplete="off">
                    </div>
                    <div class="p-3 border-b border-gray-200 relative transition-all duration-300 ease-in-out" :class="{ 'bg-gray-50/50': activeField === 'lastName' }">
                        <transition name="fade">
                            <font-awesome-icon v-if="lastName" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400" />
                        </transition>
                        <label for="lastName" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'lastName' }">Last Name</label>
                        <input type="text" id="lastName" v-model="lastName" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="Doe" @focus="activeField = 'lastName'" @blur="activeField = null" autocomplete="off">
                    </div>
                </div>
                <div class="p-3 border-b border-gray-200 relative transition-all duration-300 ease-in-out" :class="{ 'bg-gray-50/50': activeField === 'email', 'bg-red-50/50': emailExists }">
                    <transition name="fade">
                        <font-awesome-icon v-if="isValidEmail && !emailExists" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400" />
                        <font-awesome-icon v-else-if="emailExists" icon="circle-xmark" class="absolute left-3 top-[22px] text-red-500" />
                    </transition>
                    <label for="email" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'email' }">Email address</label>
                    <input type="email" id="email" v-model="email" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="johndoe@example.com" @focus="activeField = 'email'" @blur="handleEmailBlur" autocomplete="off">
                    <transition name="fade">
                        <p v-if="emailExists" class="text-sm text-red-600 pl-6">This email is already registered.</p>
                    </transition>
                </div>
                <div class="grid grid-cols-1">
                    <div class="p-3 border-b border-gray-200 relative transition-all duration-300 ease-in-out cursor-pointer" :class="{ 'bg-gray-50/50': activeField === 'state' }">
                        <transition name="fade">
                            <font-awesome-icon v-if="selectedState" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400" />
                        </transition>
                        <label for="state" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'state' }">
                            State
                        </label>
                        <div class="relative">
                            <div class="flex items-center">
                                <transition name="slide-fade" mode="out-in">
                                    <input :key="selectedState ? selectedState.abbreviation : ''" type="text" id="state" ref="stateInputRef" @keydown="handleStateKeydown" :value="selectedState ? selectedState.name : ''" readonly class="block w-full border-0 p-0 pr-8 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6 cursor-pointer" placeholder="Select a state" @focus="handleStateFocus" @blur="handleStateBlur" autocomplete="off">
                                </transition>
                            </div>
                            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                                <div v-show="isDropdownOpen" class="absolute z-[999] mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm state-dropdown" @click.stop>
                                    <div v-if="mainStore.statesArray.length === 0" class="p-4 text-gray-500 text-center font-semibold italic bg-gray-50">
                                        No states available
                                    </div>
                                    <div v-for="stateItem in mainStore.statesArray" :key="stateItem.abbreviation" @mousedown.prevent="selectState(stateItem)" class="cursor-pointer select-none relative py-2 px-4 hover:bg-gray-100 transition-all duration-300 ease-in-out">
                                        <div class="flex items-center justify-between">
                                            <span class="font-medium text-gray-800">{{ stateItem.name }}</span>
                                            <span class="text-sm text-gray-500">{{ stateItem.abbreviation }}</span>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
                <div class="p-3 border-b border-gray-200 relative transition-all duration-300 ease-in-out" :class="{ 'bg-gray-50/50': activeField === 'zipCode' }">
                    <transition name="fade">
                        <font-awesome-icon v-if="isValidZipCode" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400" />
                    </transition>
                    <label for="zipCode" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'zipCode' }">ZIP Code</label>
                    <input type="text" id="zipCode" v-model="zipCode" @input="formatZipCode" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="12345" @focus="activeField = 'zipCode'" @blur="activeField = null" autocomplete="off">
                </div>
                <div class="p-3 border-b border-gray-200 relative transition-all duration-300 ease-in-out" :class="{ 'bg-gray-50/50': activeField === 'phone' }">
                    <transition name="fade">
                        <font-awesome-icon v-if="isValidPhone" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400" />
                    </transition>
                    <label for="phone" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'phone' }">Phone</label>
                    <input type="tel" id="phone" v-model="phone" @input="formatPhoneNumber" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="(XXX) XXX-XXXX" @focus="activeField = 'phone'" @blur="activeField = null" autocomplete="off">
                </div>
                <div class="grid grid-cols-1">
                    <div class="p-3 border-b border-gray-200 relative transition-all duration-300 ease-in-out" :class="{ 'bg-gray-50/50': activeField === 'password' }">
                        <transition name="fade">
                            <font-awesome-icon v-if="isValidPassword" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400 z-[99]" />
                        </transition>
                        <label for="password" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'password' }">Password</label>
                        <div class="relative">
                            <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" required class="block w-full border-0 p-0 pr-10 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="••••••••" @focus="activeField = 'password'" @blur="activeField = null" autocomplete="new-password">
                            <button type="button" @click="togglePasswordVisibility('password')" class="absolute inset-y-0 right-0 flex items-center pr-3 transition-all duration-300 ease-in-out hover:text-teal-600">
                                <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" class="h-4 w-4 text-gray-400" />
                            </button>
                        </div>
                        <transition name="slide-fade">
                            <ul v-if="(activeField ==='password' && remainingCriteria.length != 0) || (remainingCriteria.length != 0 && showPassword)" class="text-xs text-gray-500 mt-2 pl-6">
                                <li v-for="requirement in remainingCriteria">{{ requirement }}</li>
                            </ul>
                        </transition>
                    </div>
                    <div class="p-3 relative transition-all duration-300 ease-in-out" :class="{ 'bg-gray-50/50': activeField === 'confirmPassword' }">
                        <transition name="fade">
                            <font-awesome-icon v-if="isValidConfirmPassword" icon="circle-check" class="absolute left-3 top-[22px] text-teal-400" />
                        </transition>
                        <label for="confirmPassword" class="block text-xs font-semibold text-gray-500 transition-all duration-300 ease-in-out pl-6" :class="{ 'text-teal-600 text-[10px]': activeField === 'confirmPassword' }">Confirm Password</label>
                        <div class="relative">
                            <input :type="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" v-model="confirmPassword" required class="block w-full border-0 p-0 text-gray-700 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6 bg-transparent font-medium pl-6" placeholder="••••••••" @focus="activeField = 'confirmPassword'" @blur="activeField = null" autocomplete="off">
                        </div>
                    </div>
                </div>

                <button type="submit" :disabled="!formValid" class="w-full py-3 text-white transition duration-300 ease-in-out flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500" :class="{ 'bg-gray-400 cursor-not-allowed': !formValid, 'bg-teal-400 hover:bg-teal-400/90 cursor-pointer': formValid }">
                    <transition name="slide-fade" mode="out-in">
                        <span v-if="formValid" key="valid" class="text-[16px] mr-2">Next: Choose a profile image
                            <font-awesome-icon icon="fa-solid fa-arrow-right" class="text-white ml-2" /></span>
                        <span v-else key="invalid" class="text-[16px] mr-2">Please complete the form</span>
                    </transition>
                </button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    ref,
    computed,
    onMounted,
    onBeforeUnmount,
    watch,
    reactive,
    nextTick
} from 'vue';
import useMainStore from "@/stores/main";
import axios from 'axios';

export default {
    setup(props, {
        emit
    }) {
        const firstName = ref('');
        const lastName = ref('');
        const email = ref('');
        const phone = ref('');
        const password = ref('');
        const confirmPassword = ref('');
        const zipCode = ref('');
        const activeField = ref(null);
        const showPassword = ref(false);
        const showConfirmPassword = ref(false);
        const isLoading = ref(false);
        const isDropdownOpen = ref(false);
        const selectedState = ref(null);
        const stateInputRef = ref(null);
        const emailExists = ref(false);
        const mainStore = useMainStore();

        const formValid = computed(() => {
            return firstName.value && lastName.value && isValidEmail.value && !emailExists.value && isValidPhone.value &&
                isValidZipCode.value && isValidPassword.value && isValidConfirmPassword.value && isValidState.value;
        });

        const isValidEmail = computed(() => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email.value);
        });

        const handleEmailBlur = async () => {
            if (isValidEmail.value) {
                try {
                    const response = await axios.post(`${import.meta.env.VITE_BASE_LINK}/check-email-exists`, {
                        email: email.value
                    });
                    emailExists.value = response.data.exists;
                    emit('check-form-validated', formValid.value);
                } catch (error) {
                    console.error('Error checking email:', error);
                    emit('check-form-validated', formValid.value);
                    // Handle error (e.g., show a generic error message)
                }
            }
            activeField.value = null;
        };

        const isValidPhone = computed(() => {
            const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;
            return phoneRegex.test(phone.value);
        });

        const isValidConfirmPassword = computed(() => {
            return password.value === confirmPassword.value && confirmPassword.value !== '';
        });

        const isValidZipCode = computed(() => {
            const zipCodeRegex = /^\d{5}$/;
            return zipCodeRegex.test(zipCode.value);
        });

        const isValidState = computed(() => {
            return !!selectedState.value;
        });

        const passwordCriteria = reactive([
            "8 characters",
            "One uppercase letter",
            "One lowercase letter",
            "One number",
            "One special character (!@#$%^&*)"
        ]);

        const remainingCriteria = computed(() => {
            const pwd = password.value;
            return passwordCriteria.filter(criterion => {
                if (criterion === "8 characters" && pwd.length >= 8) return false;
                if (criterion === "One uppercase letter" && /[A-Z]/.test(pwd)) return false;
                if (criterion === "One lowercase letter" && /[a-z]/.test(pwd)) return false;
                if (criterion === "One number" && /\d/.test(pwd)) return false;
                if (criterion === "One special character (!@#$%^&*)" && /[!@#$%^&*]/.test(pwd)) return false;
                return true;
            });
        });

        const isValidPassword = computed(() => remainingCriteria.value.length === 0);

        const passwordStrength = computed(() => {
            let strength = 0;
            if (password.value.length >= 8) strength += 20;
            if (/[A-Z]/.test(password.value)) strength += 20;
            if (/[a-z]/.test(password.value)) strength += 20;
            if (/\d/.test(password.value)) strength += 20;
            if (/[!@#$%^&*]/.test(password.value)) strength += 20;
            return strength;
        });

        const passwordStrengthText = computed(() => {
            if (passwordStrength.value < 33) return 'Weak';
            if (passwordStrength.value >= 33 && passwordStrength.value < 66) return 'Medium';
            return 'Strong';
        });

        const passwordStrengthColor = computed(() => {
            if (passwordStrength.value < 33) return 'red';
            if (passwordStrength.value >= 33 && passwordStrength.value < 66) return 'yellow';
            return 'green';
        });

        const handleStateFocus = () => {
            activeField.value = 'state';
            isDropdownOpen.value = true;
        };

        const handleStateBlur = (event) => {
            setTimeout(() => {
                if (!event.relatedTarget || !event.relatedTarget.closest('.state-dropdown')) {
                    isDropdownOpen.value = false;
                    activeField.value = null;
                }
            }, 100);
        };

        const handleStateKeydown = (event) => {
            if (event.key.length === 1 && /[a-zA-Z]/.test(event.key)) {
                isDropdownOpen.value = true;
                const matchingState = mainStore.statesArray.find(s =>
                    s.name.toLowerCase().startsWith(event.key.toLowerCase())
                );
                if (matchingState) {
                    selectedState.value = matchingState;
                    nextTick(() => {
                        if (stateInputRef.value) {
                            stateInputRef.value.focus();
                        }
                    });
                }
            } else if (event.key === 'Tab') {
                isDropdownOpen.value = false;
            }
        };

        const selectState = (stateObj) => {
            selectedState.value = stateObj;
            isDropdownOpen.value = false;
        };

        const formatPhoneNumber = () => {
            let cleaned = phone.value.replace(/\D/g, '');
            cleaned = cleaned.slice(0, 10);
            let formatted = '';
            if (cleaned.length > 0) {
                formatted += '(' + cleaned.slice(0, 3);
                if (cleaned.length > 3) {
                    formatted += ') ' + cleaned.slice(3, 6);
                    if (cleaned.length > 6) {
                        formatted += '-' + cleaned.slice(6);
                    }
                }
            }
            phone.value = formatted;
        };

        const formatZipCode = () => {
            zipCode.value = zipCode.value.replace(/\D/g, '').slice(0, 5);
        };

        const saveUserData = () => {
            if (formValid.value) {
                emit('check-form-validated', true);
                isLoading.value = true;
                emit('form-validated', {
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    phone: phone.value,
                    state: selectedState.value.abbreviation,
                    zipCode: zipCode.value,
                    password: password.value,
                    confirmPassword: confirmPassword.value
                });
                saveFormData();
            } else {
                emit('check-form-validated', false);
            }
        };

        const saveFormData = () => {
            const formData = {
                firstName: firstName.value,
                lastName: lastName.value,
                email: email.value,
                phone: phone.value,
                state: selectedState.value ? selectedState.value.abbreviation : '',
                zipCode: zipCode.value,
                password: password.value,
                confirmPassword: confirmPassword.value
            };
            localStorage.setItem('personalDetailsFormData', JSON.stringify(formData));
        };

        const resetForm = () => {
            firstName.value = '';
            lastName.value = '';
            email.value = '';
            phone.value = '';
            password.value = '';
            confirmPassword.value = '';
            selectedState.value = null;
            zipCode.value = '';
            emailExists.value = false;
            localStorage.removeItem('personalDetailsFormData');
        };

        const loadFormData = () => {
            const savedData = localStorage.getItem('personalDetailsFormData');
            if (savedData) {
                const formData = JSON.parse(savedData);
                firstName.value = formData.firstName || '';
                lastName.value = formData.lastName || '';
                email.value = formData.email || '';
                phone.value = formData.phone || '';
                if (formData.state) {
                    const foundState = mainStore.statesArray.find(s => s.abbreviation === formData.state);
                    if (foundState) {
                        selectedState.value = foundState;
                    }
                }
                zipCode.value = formData.zipCode || '';
                password.value = formData.password || '';
                confirmPassword.value = formData.confirmPassword || '';
            }
            emit('check-form-validated', formValid.value);
        };

        const togglePasswordVisibility = (field) => {
            if (field === 'password') {
                showPassword.value = !showPassword.value;
            } else if (field === 'confirmPassword') {
                showConfirmPassword.value = !showConfirmPassword.value;
            }
        };

        onMounted(() => {
            emailExists.value = false;
            loadFormData();
            emit('check-form-validated', formValid.value);
        });

        onBeforeUnmount(() => {
            saveFormData();
        });

        return {
            firstName,
            lastName,
            email,
            phone,
            password,
            confirmPassword,
            zipCode,
            activeField,
            showPassword,
            showConfirmPassword,
            isLoading,
            isDropdownOpen,
            selectedState,
            stateInputRef,
            mainStore,
            formValid,
            isValidEmail,
            isValidPhone,
            isValidPassword,
            isValidConfirmPassword,
            isValidZipCode,
            isValidState,
            selectState,
            handleStateFocus,
            handleStateBlur,
            handleStateKeydown,
            formatPhoneNumber,
            formatZipCode,
            saveUserData,
            togglePasswordVisibility,
            saveFormData,
            resetForm,
            loadFormData,
            passwordCriteria,
            remainingCriteria,
            passwordStrengthText,
            passwordStrengthColor,
            passwordStrength,
            emailExists,
            handleEmailBlur
        };
    }
};
</script>
