<template>
<div class="relative flex items-center justify-center shadow-4xl overflow-hidden w-full translate duration-200 transition pl-8">
    <transition name="slide-fade" mode="out-in">
        <div class="w-full shadow-2xl mx-auto transition-all duration-300 h-[100vh] overflow-hidden relative" :class="{ 'opacity-50 blur-sm': isRegistering }">
            <div class="py-12 relative z-10">
                <div key="account-overview-and-form" class="mx-auto h-[110vh] -mt-[50px] shadow-4xl overflow-hidden animate-slide-fade-right flex items-center justify-center">

                    <div class="p-6 bg-white h-[110vh] w-full bg-opacity-90 backdrop-filter backdrop-blur-sm transform transition-all duration-300 ease-in-out flex items-center justify-center" style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3), 0 0 20px 0px rgba(0, 200, 200, 0.2);">
                        <div class="max-w-2xl w-full -mt-8">
                            <transition name="slide-fade" mode="out-in">
                                <div v-if="!showStateMap" key="user-data">
                                    <!-- Profile Block -->
                                    <div class="flex items-center mb-6 pb-3 border-b border-gray-200">
                                        <img :src="profileImageSrc" alt="Profile" class="w-24 h-24 object-cover rounded-full border-2 border-teal-500 mr-4 shadow-sm" />
                                        <div>
                                            <h2 class="text-xl text-gray-500">Almost there</h2>
                                            <h2 class="text-4xl font-bold text-gray-800">{{ `${formData.firstName} ${formData.lastName}` }}!</h2>
                                            <!-- <p class="text-lg text-gray-600">Verify your information</p> -->
                                        </div>
                                    </div>

                                    <!-- Data Block -->
                                    <div class="grid grid-cols-2 gap-4">
                                        <div v-for="field in fields.filter(f => !f.isCustom)" :key="field.key" class="border-b border-gray-200 pb-2">
                                            <span class="text-xs text-gray-600 block mb-1">{{ field.title }}</span>
                                            <span v-if="field.key !== 'password'" class="text-sm text-gray-800 font-medium truncate">
                                                {{ field.key === 'name' ? `${formData.firstName} ${formData.lastName}` : formData[field.key] || field.example }}
                                            </span>
                                            <div v-else class="flex items-center">
                                                <span class="text-sm text-gray-800 font-medium">{{ showPassword ? formData.password : '••••••••' }}</span>
                                                <button @click="togglePassword" class="ml-2 text-teal-500 hover:text-teal-700 transition duration-300 ease-in-out">
                                                    <font-awesome-icon :icon="showPassword ? 'fa-eye-slash' : 'fa-eye'" class="text-xs" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <h3 v-if="fields.filter(f => f.isCustom).length != 0" class="text-black font-bold text-xs mt-4">NY Cheer Options:</h3>
                                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-2">
                                        <div v-for="field in fields.filter(f => f.isCustom)" :key="field.key" class="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
                                            <div class="flex items-center mb-2">
                                                <font-awesome-icon :icon="field.key == 'Judge' ? field.icon : field.icon2" class="text-teal-500 mr-2" />
                                                <span class="text-xs text-gray-600 font-medium uppercase tracking-wide">{{ field.title }}</span>
                                            </div>
                                            <span class="text-sm text-gray-800 block truncate">{{ formData[field.key] || field.example }}</span>
                                            <!-- <div class="mt-2 w-full h-0.5 bg-gradient-to-r from-teal-200 to-blue-200 rounded-full opacity-50"></div> -->
                                        </div>
                                    </div>

                                    <!-- Button Block -->
                                    <div class="mt-6">
                                        <div class="mb-6">
                                            <p class="text-sm mb-1">Are you a part of a state organization?</p>
                                            <button @click="showStateMap = true" class="w-full py-2 text-gray-700 bg-gray-100 rounded-md transition duration-300 ease-in-out flex items-center justify-between border-2 border-transparent hover:border-gradient-to-r from-teal-400 to-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 hover:scale-[1.01] group overflow-hidden">
                                                <div class="flex items-center px-4 relative w-full">
                                                    <img :src="'https://d3sjyulja6yeep.cloudfront.net/files/states.png'" class="mr-2 text-teal-500 w-auto h-6 absolute -left-12 transition-all duration-300 ease-in-out group-hover:left-2" />
                                                    <span class="text-[16px] transition-all duration-300 ease-in-out group-hover:ml-[35px]">{{ selectedStateAssociation != '' ? selectedStateAssociation : 'Select State Association' }}</span>
                                                </div>
                                                <font-awesome-icon icon="fa-solid fa-arrow-right" class="text-teal-500 px-4 flex-shrink-0" />
                                            </button>
                                        </div>

                                        <div class="flex items-center mb-3">
                                            <input type="checkbox" id="acceptTerms" v-model="acceptedTerms" class="w-4 h-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 transition-all duration-300 ease-in-out" />
                                            <label for="acceptTerms" class="ml-2 block text-sm text-gray-700">
                                                I accept the <a href="https://www.refreps.com/terms" class="underline font-medium text-teal-400 hover:text-teal-400/90 transition-all duration-300 ease-in-out" target="_blank">terms of service</a>
                                            </label>
                                        </div>
                                        <RocketButton @click="register" :isClicked="isLaunching" :isRegistering="isRegistering" :disabled="!acceptedTerms" :accepted-terms="acceptedTerms" class="w-full shadow-sm" />
                                        <div v-if="errorMessage" class="mt-3 text-xs text-red-600 text-center font-medium bg-red-50 p-2 rounded-md border border-red-200">
                                            {{ errorMessage }}
                                        </div>
                                    </div>
                                </div>
                                <div v-else key="state-map" class="w-full h-full">
                                    <button @click="showStateMap = false" class="mb-4 text-gray-600 hover:text-gray-800 transition duration-300">
                                        <font-awesome-icon icon="fa-solid fa-arrow-left" class="mr-2" /> Back to Overview
                                    </button>
                                    <StatesMap :state="formData.state" @state-selected="handleStateSelection" @back-to-overview="showStateMap = false" />
                                </div>
                            </transition>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>

    <div v-if="isRegistering" class="absolute w-full h-full flex items-end justify-center">
        <transition name="slide-fade" appear>
            <div v-if="showRocket" :class="{ exiting: isExiting }" class="relative w-full flex items-end justify-center" style="height: calc(100% - 40px)">
                <RegisterRocket :isExiting="isExiting" @mounted="startRocketAnimation" @animation-complete="rocketCentered" v-if="showRocket" />
            </div>
        </transition>
    </div>
</div>
</template>

<script>
import RegisterRocket from './RegisterRocket.vue'
import RocketButton from './RocketButton.vue'
import StatesMap from './StatesMap.vue'
import gsap from 'gsap'

export default {
    name: 'SignUpForm',
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    components: {
        StatesMap,
        RocketButton,
        RegisterRocket
    },
    data() {
        return {
            isLaunching: false,
            preparingForLaunch: false,
            formData: this.userData,
            showStateMap: false,
            selectedStateAssociation: '',
            showPassword: false,
            isRegistering: false,
            isExiting: false,
            showRocket: false,
            errorMessage: '',
            acceptedTerms: false,
            fields: [{
                    icon: 'fa-duotone fa-solid fa-user',
                    title: 'Name',
                    key: 'name',
                    example: 'John Doe',
                    isCustom: false
                },
                {
                    icon: 'fa-duotone fa-solid fa-envelope',
                    title: 'Email',
                    key: 'email',
                    example: 'john.doe@example.com',
                    isCustom: false
                },
                {
                    icon: 'fa-duotone fa-solid fa-map-pin',
                    title: 'Zip Code',
                    key: 'zipCode',
                    example: '90210',
                    isCustom: false
                },
                {
                    icon: 'fa-duotone fa-solid fa-map',
                    title: 'State',
                    key: 'state',
                    example: 'California',
                    isCustom: false
                },
                {
                    icon: 'fa-duotone fa-solid fa-phone',
                    title: 'Phone',
                    key: 'phone',
                    example: '864 551 3744',
                    isCustom: false
                },
                {
                    icon: 'fa-duotone fa-solid fa-lock',
                    title: 'Password',
                    key: 'password',
                    example: 'Alpha1234!',
                    isCustom: false
                },
                
                                ...(this.userData.companyID === "4b39f904-bd23-4bee-a536-bc9571ff37cb" ? [
                                    {
                                        icon: 'fa-duotone fa-solid fa-sitemap',
                                        icon2: 'fa-duotone fa-solid fa-sitemap',
                                        title: 'Section',
                                        key: 'section',
                                        example: 'Marketing',
                                        isCustom: true
                                    },
                                    {
                                        icon: 'fa-duotone fa-solid fa-gavel',
                                        icon2: 'fa-duotone fa-solid fa-user',
                                        title: 'Position',
                                        key: 'position',
                                        example: 'Manager',
                                        isCustom: true
                                    }
                                ] : [])
                
            ],
            profileImageSrc: 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg'
        }
    },
    watch: {
        acceptedTerms(newValue) {
            this.$emit('accepted-terms-changed', newValue)
            localStorage.setItem('acceptedTerms', JSON.stringify(newValue))
        },
        selectedStateAssociation(newValue) {
            localStorage.setItem('selectedStateAssociation', newValue)
        }
    },
    methods: {
        startRocketAnimation() {
            this.isExiting = false
            this.showRocket = true
        },
        rocketCentered() {
            // The rocket has reached the center, start the timer for exit
            setTimeout(() => {
                this.isExiting = true
                // Hide the rocket after the exit animation
                setTimeout(() => {
                    this.showRocket = false
                }, 1000) // This should match the transition duration in the component
                setTimeout(async () => {

                    try {
                    const formData = {
                        email: this.formData.email,
                        password: this.formData.password,
                        name: `${this.formData.firstName} ${this.formData.lastName}`,
                        firstName: this.formData.firstName,
                        lastName: this.formData.lastName,
                        zipCode: this.formData.zipCode,
                        state: this.formData.state,
                        phone: this.formData.phone,
                        stateAssociation: this.selectedStateAssociation,
                        companyID: this.formData.companyID,
                        metadata: this.formData.metadata || {},
                        cameFromJupiter: false
                    };

                    const response = await this.axios.post(`${import.meta.env.VITE_BASE_LINK}/register`, formData);
    
                    this.isRegistering = false
                    this.$emit('register-complete', this.formData);

                } catch (error) {
                    console.error('Registration failed:', error);
                    this.errorMessage = error.response?.data?.message || 'An error occurred during registration. Please try again.';
                } finally {
                    this.isRegistering = false;
                }
                    
                    
                }, 500) // This should match the transition duration in the component
            }, 3000) // Adjust this value to control how long the rocket stays centered
        },
        handleStateSelection(stateAssociation) {
            this.selectedStateAssociation = stateAssociation
            this.showStateMap = false
        },
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        beforeEnter(el) {
            el.style.opacity = 0
            el.style.transform = 'translateY(20px)'
        },
        enter(el, done) {
            gsap.to(el, {
                opacity: 1,
                y: 0,
                duration: 0.3,
                delay: el.dataset.index * 0.1,
                onComplete: done
            })
        },
        leave(el, done) {
            gsap.to(el, {
                opacity: 0,
                y: 20,
                duration: 0.3,
                delay: el.dataset.index * 0.1,
                onComplete: done
            })
        },
        async register() {
            this.isLaunching = true
            this.errorMessage = ''

            setTimeout(() => {
                this.isLaunching = false
                this.isRegistering = true
                this.startRocketAnimation()
            }, 600)
               
        },
        loadProfileImage() {
            if (this.formData.profileImage) {
                const img = new Image()
                img.onload = () => {
                    this.profileImageSrc = this.formData.profileImage
                }
                img.onerror = () => {
                    console.error('Failed to load profile image')
                }
                img.src = this.formData.profileImage
            }
        },
        loadAcceptedTerms() {
            const savedTerms = localStorage.getItem('acceptedTerms')
            if (savedTerms !== null) {
                this.acceptedTerms = JSON.parse(savedTerms)
            }
        },
        loadSelectedStateAssociation() {
            const savedStateAssociation = localStorage.getItem('selectedStateAssociation')
            if (savedStateAssociation !== null) {
                this.selectedStateAssociation = savedStateAssociation
            }
        }
    },
    mounted() {
        this.loadProfileImage()
        this.loadAcceptedTerms()
        this.loadSelectedStateAssociation()
    }
}
</script>

<style scoped>
body {
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f8f9fa;
}

.card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    width: 800px;
    display: flex;
    transition: box-shadow 0.3s ease;
}

.card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.left-panel {
    width: 200px;
    background-color: #e9ecef;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dee2e6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    color: #495057;
    margin-bottom: 20px;
    overflow: hidden;
}

.name {
    font-size: 18px;
    font-weight: 600;
    color: #212529;
    text-align: center;
}

.right-panel {
    flex-grow: 1;
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.data-item {
    display: flex;
    flex-direction: column;
}

.data-label {
    font-size: 12px;
    color: #6c757d;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
}

.data-value {
    font-size: 16px;
    color: #212529;
    font-weight: 500;
}

.footer {
    grid-column: 1 / -1;
    font-size: 12px;
    color: #adb5bd;
    text-align: right;
    margin-top: 10px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.slide-fade-bottom-enter-active,
.slide-fade-bottom-leave-active {
    transition: all 0.5s ease;
}

.slide-fade-bottom-enter-from {
    transform: translateY(100%);
    opacity: 0;
}

.slide-fade-bottom-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
</style>
