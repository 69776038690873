<template>
<div class="w-full relative flex h-screen" style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M1 1h2v2H1V1zm4 0h2v2H5V1zm4 0h2v2H9V1zm4 0h2v2h-2V1zm4 0h2v2h-2V1zm-16 4h2v2H1V5zm4 0h2v2H5V5zm4 0h2v2H9V5zm4 0h2v2h-2V5zm4 0h2v2h-2V5zm-16 4h2v2H1V9zm4 0h2v2H5V9zm4 0h2v2H9V9zm4 0h2v2h-2V9zm4 0h2v2h-2V9zm-16 4h2v2H1v-2zm4 0h2v2H5v-2zm4 0h2v2H9v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z\' fill=\'%239C92AC\' fill-opacity=\'0.05\' fill-rule=\'evenodd\'/%3E%3C/svg%3E'); box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.3), 0 0 20px 0px rgba(0, 200, 200, 0.2);">

    <div class="absolute w-full h-full flex items-center justify-center p-8 overflow-y-auto">
        <div class="max-w-lg w-full">

            <password-reset-view @reset-password="handleResetPassword" :reset-error="resetError" />

            <footer class="mt-8 text-center text-sm text-gray-500">
                <p>©2024 All Rights Reserved. RefReps, LLC is a registered trademark.</p>
                <p>
                    <a href="https://www.refreps.com/privacy" target="_blank" class="text-teal-600 hover:underline">Privacy</a> ·
                    <a href="https://www.refreps.com/terms" target="_blank" class="text-teal-600 hover:underline">Terms</a> </p>
            </footer>
        </div>
    </div>
</div>
</template>

  
  
<script>
import {
    ref,
    reactive,
    onMounted,
    computed,
    watch
} from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import PasswordResetView from '@/components/Login/ForgotPassword/PasswordResetView.vue'

export default {
    name: 'ForcePasswordReset',
    components: {
        PasswordResetView
    },
    props: {
        userId: {
            type: String,
            default: ''
        }
    },
    setup(props, {
        emit
    }) {
        const resetError = ref('');
        const resetToken = ref('');
        const newPassword = ref('');
        const router = useRouter();

        const handleResetPassword = async (password) => {
   
            try {
                const response = await axios.post(`${import.meta.env.VITE_BASE_LINK}/force-reset-password`, {
                    userId: props.userId,
                    password: password,
                });

                if (response.data.code === 200) {
                    resetError.value = '';
                    router.push('/dashboard');
                } else {
                    throw new Error(response.data.message || 'An error occurred while resetting the password.');
                }
            } catch (error) {
                console.error('Error resetting password:', error);
                resetError.value = error.response?.data?.message || error.message || 'An error occurred while resetting the password. Please try again.';
            }
        };

        const returnToLoginFromReset = () => {
            // Implement the logic to return to login page
            // This function was missing in the original code
        };

        return {
            handleResetPassword,
            resetError
        }
    }
}
</script>
  
  
<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
