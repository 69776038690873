<template>
<div v-show="isVisible && !isRegistering" class="rocket-container" :class="{ 'entered': hasEntered, 'exiting': isExiting }">
    <div class="w-[500px] h-[500px]">
        <div class="rocket-wrapper mb-[100px]">
            <font-awesome-icon icon="fa-solid fa-rocket" class="w-[50px] h-[50px] rocket" />
            <div class="absolute bottom-[16px] left-[10%] transform -translate-x-[45%]">
                <div v-for="i in 15" :key="i" :class="['particle', `p${i}`]" :style="particleStyle(i)"></div>
            </div>
            <div class="submit-message" :class="{ 'visible': hasEntered }">
            <div class="text-center rotate-45 -top-[45px] left-[50px] absolute">
                <p class="text-xl">Setting up your account...</p>
                <p class="text-sm">This may take a few seconds...</p>
            </div>
        </div>
        </div>
        
    </div>

</div>
</template>

<script>
import {
    ref,
    onMounted,
    watch
} from 'vue';

export default {
    name: 'RegisterRocket',
    props: {
        isExiting: {
            type: Boolean,
            default: false
        },
        isRegistering: {
            type: Boolean,
            default: false
        }
    },
    emits: ['mounted', 'animation-complete'],
    setup(props, {
        emit
    }) {
        const isVisible = ref(false);
        const hasEntered = ref(false);

        onMounted(() => {
            emit('mounted');
            setTimeout(() => {
                isVisible.value = true;
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        hasEntered.value = true;
                        setTimeout(() => {
                            emit('animation-complete');
                        }, 500);
                    });
                });
            }, 200);
        });

        watch(() => props.isExiting, (newValue) => {
            if (newValue) {
                hasEntered.value = false;
            }
        });

        const particleStyle = (index) => {
            const gray = 128 + Math.floor(Math.random() * 128);
            const size = 4 + Math.floor(Math.random() * 6);
            return {
                backgroundColor: `rgba(${gray}, ${gray}, ${gray}, ${0.5 + Math.random() * 0.5})`,
                '--tx': `${-20 + Math.random() * 40}px`,
                '--ty': `${20 + Math.random() * 30}px`,
                width: `${size}px`,
                height: `${size}px`,
                animationDuration: `${0.2 + Math.random() * 0.1}s`
            };
        };

        return {
            isVisible,
            hasEntered,
            particleStyle
        };
    }
};
</script>

<style scoped>
.submit-message {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 24px;
    opacity: 0;
    transition: opacity 0.2s ease;
    width: 400px;
    text-align: center;
}

.submit-message.visible {
    opacity: 1;
}

.rocket-container {
    position: absolute;
    left: 50%;
    bottom: 33vh;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
    transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    will-change: transform, opacity;
}

.rocket-container.entered {
    bottom: 60%;
    transform: translate(-50%, 50%);
    opacity: 1;
}

.rocket-container.exiting {
    bottom: 110%;
    transform: translate(-50%, 0);
    opacity: 0;
}

.rocket-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    animation: drift 2s ease-in-out infinite;
}

.rocket {
    display: block;
}

.particles-container {
    position: absolute;
    bottom: -25px;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translateX(-50%) rotate(45deg);
}

.particle {
    position: absolute;
    border-radius: 50%;
    animation: particleAnimation ease-out infinite;
}

@keyframes particleAnimation {
    0% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translate(var(--tx), var(--ty)) scale(0);
        opacity: 0;
    }
}

@keyframes drift {

    0%,
    100% {
        transform: translateX(-50%) rotate(-45deg);
    }

    25% {
        transform: translate(-45%, -5%) rotate(-45deg);
    }

    50% {
        transform: translate(-55%, 0%) rotate(-45deg);
    }

    75% {
        transform: translate(-50%, 5%) rotate(-45deg);
    }
}

.p1 {
    animation-delay: 0.01s;
}

.p2 {
    animation-delay: 0.02s;
}

.p3 {
    animation-delay: 0.03s;
}

.p4 {
    animation-delay: 0.04s;
}

.p5 {
    animation-delay: 0.05s;
}

.p6 {
    animation-delay: 0.06s;
}

.p7 {
    animation-delay: 0.07s;
}

.p8 {
    animation-delay: 0.08s;
}

.p9 {
    animation-delay: 0.09s;
}

.p10 {
    animation-delay: 0.10s;
}

.p11 {
    animation-delay: 0.11s;
}

.p12 {
    animation-delay: 0.12s;
}

.p13 {
    animation-delay: 0.13s;
}

.p14 {
    animation-delay: 0.14s;
}

.p15 {
    animation-delay: 0.15s;
}
</style>
