<template>
<div class="relative z-[9999]" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity animate__animated animate__fadeIn"></div>
    <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <div class="pointer-events-auto relative w-[530px]">
                    <div class="absolute w-full flex justify-end p-4 z-[9999]">
                        <button @click="$emit('close')" type="button" class="relative rounded-md text-white hover:text-gray-50">
                            <span class="absolute -inset-2.5"></span>
                            <span class="sr-only">Close panel</span>
                            <svg class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <!-- Slide-over panel -->
                    <div class="h-full overflow-y-auto bg-white animate__animated animate__fadeInRight">
                        <div class="space-y-6 pb-16">
                            <div class="relative flex flex-col items-center mx-auto bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none">
                                <div class="relative flex h-[150px] w-full justify-center bg-cover">
                                    <img src='https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/banner.ef572d78f29b0fee0a09.png' class="absolute flex h-[150px] w-full justify-center bg-cover">
                                    <div class="relative bg-teal-400 h-[150px] w-full bg-opacity-[0.75]"></div>
                                    <div class="absolute -bottom-10 flex h-[95px] w-[95px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
                                        <img class="h-full w-full rounded-full object-cover" :src="student.info.profileImage" alt="" />
                                    </div>
                                </div>
                                <div class="mt-16 mb-1 flex flex-col items-center">
                                    <h4 class="text-xl font-bold text-navy-700 dark:text-white">
                                        {{ student.info.name }}
                                    </h4>
                                    <p class="text-base font-normal text-gray-600">{{ student.info.email }}</p>
                                </div>

                                <div data-te-chip-init data-te-ripple-init class="[word-wrap: break-word] mb-3 mt-1.5 mr-4 flex h-[32px] items-center justify-between rounded-[16px] bg-[#eceff1] px-[12px] py-0 text-[15px] font-bold normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:!shadow-none active:bg-[#cacfd1] dark:bg-neutral-600 dark:text-neutral-200">
                                    <div class="my-0 -ml-[12px] mr-[8px] h-[inherit] w-[inherit] rounded-[100%]">
                                        <font-awesome-icon icon="fa-duotone fa-loveseat" class="w-6 h-6 py-1 pl-2" />
                                    </div>
                                    {{ organizationSeats }} seats
                                </div>

                                <div class="w-full px-6">
                                    <div class="hidden sm:block">
                                        <div class="border-b border-gray-200">
                                            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                                                <a @click.prevent="currentTab = 'available'" href="#" :class="currentTab == 'available' ? 'text-teal-400 border-b-2 border-teal-400' : 'border-transparent'" class="text-gray-500 hover:border-gray-200 hover:text-gray-700 flex items-center whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
                                                    <font-awesome-icon icon="fa-duotone fa-books" class="mr-1" />
                                                    Available Content
                                                    <span class="bg-gray-100 text-gray-900 ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{ selectedStudentUnassignedIds }}</span>
                                                </a>
                                                <a @click.prevent="currentTab = 'user'" href="#" :class="currentTab == 'user' ? 'text-teal-400 border-b-2 border-teal-400' : 'border-transparent'" class="text-gray-500 hover:border-gray-200 hover:text-gray-700 flex items-center whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
                                                    <font-awesome-icon icon="fa-duotone fa-book-open-reader" class="mr-1" />
                                                    {{ student.info.name }}'s Content
                                                    <span class="bg-gray-100 text-gray-900 ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{ selectedStudentAssignedIds }}</span>
                                                </a>
                                            </nav>
                                        </div>
                                    </div>
                                </div>

                                <div class="w-full mt-6 px-6">
                                    <div class="w-full" v-for="sport in uniq(filteredCourses.map(c => c.sport))">
                                        <label v-if="currentTab == 'available' && filteredCourses.filter(c => c.sport == sport && !student.courseIds.includes(c.id)).length != 0" class="text-xs text-gray-500 uppercase dark:text-gray-400">{{ sport }}</label>
                                        <ul v-if="currentTab == 'available'" role="list" class="px-3 divide-y divide-gray-100">
                                            <li v-for="course in filteredCourses.filter(c => c.sport == sport && !student.courseIds.includes(c.id))" :key="course.id" class="flex items-center justify-between gap-x-6 py-5">
                                                <div class="flex min-w-0 gap-x-4">
                                                    <img class="h-12 w-12 flex-none rounded-full object-cover bg-gray-50" :src="`https://api.refreps.com/images/${course.id}.webp`" alt="">
                                                    <div class="min-w-0 flex-auto">
                                                        <p class="text-[15px] font-semibold leading-6 text-gray-900">{{ course.name }}</p>
                                                        <p class="text-[15px] font-regular leading-6 text-gray-300">{{ course.type == 'course' ? 'Course' : 'POV' }}</p>
                                                    </div>
                                                </div>
                                                <a v-if="!idsAssigning.includes(course.id)" @click.prevent="assignCourse(course)" href="#" class="rounded-full shrink-0 px-2.5 py-1 border text-xs font-semibold bg-teal-400 text-white shadow-sm">Assign</a>
                                                <font-awesome-icon v-if="idsAssigning.includes(course.id)" icon="fa-duotone fa-spinner-third" class="w-6 h-6" spin />
                                            </li>
                                        </ul>

                                        <label v-if="currentTab == 'user' && filteredCourses.filter(c => c.sport == sport && student.courseIds.includes(c.id)).length != 0" class="text-xs text-gray-500 uppercase dark:text-gray-400">{{ sport }}</label>
                                            <ul v-if="currentTab == 'user'" role="list" class="px-3 divide-y divide-gray-100">
                                                <li v-for="course in filteredCourses.filter(c => c.sport == sport && allIds.includes(c.id))" class="flex items-center justify-between gap-x-6 py-5">
                                                    <div class="flex min-w-0 gap-x-4">
                                                        <img class="h-12 w-12 flex-none rounded-full object-cover bg-gray-50" :src="`https://api.refreps.com/images/${course.id}.webp`" alt="">
                                                        <div class="min-w-0 flex-auto">
                                                            <p class="text-[15px] font-semibold leading-6 text-gray-900">{{ course.name }}</p>
                                                            <p class="text-[15px] font-regular leading-6 text-gray-300">{{ course.type == 'course' ? 'Course' : 'POV' }}</p>
                                                            <!-- <p class="mt-1 truncate text-[14px] leading-5 text-gray-500" :class="course.shouldTakeSeats && !mainStore.selectedStudent.consumedIds.includes(course.id) ? 'text-teal-400' : 'text-gray-400'">{{ course.shouldTakeSeats && !mainStore.selectedStudent.consumedIds.includes(course.id) ? '1 seat' : '0 seats' }}</p> -->
                                                        </div>
                                                    </div>
                                                    <a @click.prevent="!student.consumedIds.includes(course.id) || course.type == 'pov' ? unassignCourse(course) : doNothing()" v-if="!idsUnAssigning.includes(course.id)" href="#" :disabled="student.consumedIds.includes(course.id)" :class="!student.consumedIds.includes(course.id) || course.type == 'pov' ? 'bg-gray-200 text-gray-500 opacity-100' : 'bg-white hover:bg-gray-50 opacity-25 cursor-not-allowed'" class="rounded-full px-2.5 py-1 border text-xs font-semibold text-gray-900 shadow-sm">{{ !student.consumedIds.includes(course.id) ? student.courseIds.includes(course.id) || course.type == 'pov' ? 'Remove' : 'Assign' : 'Consumed' }}</a>
                                                    <!-- <a @click.prevent="student.courseIds.includes(selectedCourse.id) ? doNothing() : assignCourse(student)" v-if="!idsAssigning.includes(student.info.id)" href="#" :class="student.consumedIds.includes(selectedCourse.id) ? 'cursor-not-allowed' : student.courseIds.includes(selectedCourse.id) ? 'bg-gray-200 text-gray-500' : 'bg-teal-400 text-white'" class="rounded-full px-2.5 py-1 border text-xs font-semibold text-gray-500 shadow-sm">{{ !student.consumedIds.includes(selectedCourse.id) ? student.courseIds.includes(selectedCourse.id) ? 'Remove' : 'Assign' : 'Consumed' }}</a> -->
                                                    <font-awesome-icon v-if="idsUnAssigning.includes(course.id)" icon="fa-duotone fa-spinner-third" class="w-6 h-6" spin />
                                                </li>
    
                                            </ul>
                                    </div>
                                    <p class="text-center w-full text-gray-400" v-if="currentTab == 'available' && filteredCourses.filter(c => !student.courseIds.includes(c.id)).length == 0">No courses found.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapStores
} from 'pinia';
import useMainStore from '@/stores/main';

export default {
    props: {
        student: {
            type: Object,
            required: true
        },
        organizationSeats: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentTab: 'available',
            searchTerm: '',
            idsAssigning: [],
            idsUnAssigning: [],
            allIds: []
        };
    },
    computed: {
        ...mapStores(useMainStore),
        filteredCourses() {
            return this.mainStore.courses.filter(item => {
                return item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
        selectedStudentAssignedIds() {
            return this.student.courseIds.length;
        },
        selectedStudentUnassignedIds() {
            return this.filteredCourses.filter(c => !this.student.courseIds.includes(c.id)).length;
        }
    },
    methods: {
        pushPullCourse(course) {

            if (this.mainStore.willAssign.map(c => c.id).includes(course.id)) {
                this.mainStore.willAssign = this.mainStore.willAssign.filter(item => item.id != course.id)
                this.projectedSeats = this.mainStore.organization.seats - this.mainStore.willAssign.filter(c => c.type == 'course' && c.shouldTakeSeats).length
            } else {
                if (this.projectedSeats == 0) return alert('You have no more seats available.')
                this.mainStore.willAssign.push({
                    id: course.id,
                    name: course.name,
                    type: course.type,
                    shouldTakeSeats: course.shouldTakeSeats
                })
                this.projectedSeats = this.mainStore.organization.seats - this.mainStore.willAssign.filter(c => c.type == 'course' && c.shouldTakeSeats).length
            }
        },
        uniq(a) {
            return Array.from(new Set(a));
        },
        async assignCourse(course) {

            if (this.mainStore.organization.seats <= 0 && course.type == 'course') return this.mainStore.showAddSeats = true

            this.idsAssigning.push(course.id)
            this.axios.post(`${import.meta.env.VITE_BASE_LINK}/assign-course-to-student`, {
                    user_id: this.student.info.id,
                    type: course.type,
                    instructor: this.mainStore.user,
                    companyID: this.$route.params.id,
                    course_id: course.id,
                    userType: course.id == '4CBED23C-C7FA-49F3-A29C-CD1A5B21CBD3' ? this.cheerSelection : 'official'
                })
                .then(response => {
                    if (response.data.code == 200) {

                        this.idsAssigning = this.idsAssigning.filter(id => id != course.id)
                        this.student.courseIds.push(course.id)
                        this.mainStore.organization.seats -= course.shouldTakeSeats ? 1 : 0
                        if (course.type == 'course') {
                            this.student.courseIds.push(course.id)
                        } else {
                            this.student.interactiveIDs.push(course.id)
                        }
                        this.getUserCourses()

                    } else {
                        this.idsAssigning = this.idsAssigning.filter(id => id != course.id)
                        alert('There was an error assigning this course.')
                    }
                })
                .catch(function (error) {
                    alert(error)
                    student.isAssigningCourse = false
                    console.log(error);
                });

        },
        async unassignCourse(course) {

            this.idsUnAssigning.push(course.id)
            await this.axios.post(course.type == 'course' ? `${import.meta.env.VITE_BASE_LINK}/remove-course` : `${import.meta.env.VITE_BASE_LINK}/remove-pack`, {
                    user_id: this.student.info.id,
                    course_id: course.id,
                    companyID: this.$route.params.id
                })
                .then(response => {
                    if (response.data.code == 200) {
                        this.idsUnAssigning = this.idsUnAssigning.filter(id => id != course.id)
                        this.student.courseIds = this.student.courseIds.filter(id => id != course.id)
                        this.mainStore.organization.seats += course.shouldTakeSeats ? 1 : 0
                        if (course.type == 'course') {
                            this.student.consumedIds = this.student.consumedIds.filter(id => id != course.id)
                        } else {
                            this.student.interactiveIDs = this.student.interactiveIDs.filter(id => id != course.id)
                        }
                        this.getUserCourses()

                    } else {
                        this.idsUnAssigning = this.idsUnAssigning.filter(id => id != course.id)
                        alert('There was an error un-assigning this course.')
                    }
                })
                .catch(function (error) {
                    alert(error)
                    this.idsUnAssigning = this.idsUnAssigning.filter(id => id != course.id)
                    console.log(error);
                });
        },

        doNothing() {
            return
        },
        getUserCourses() {
            this.allIds = [...this.student.courseIds, ...this.student.interactiveIDs]
        }
    },
    mounted() {
        this.getUserCourses()
    },
};
</script>
